import { graphql } from 'gatsby';
import * as React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import PrivacySection from '../components/PrivacySection/PrivacySection';
import Layout from '../components/layout';
import Seo from '../components/seo';

const PrivacyPage = ({ data, location }) => {
  const companyName = data.site.siteMetadata.companyName;
  return (
    <Layout>
      <Breadcrumb
        crumbs={[
          { pathname: '/', crumbLabel: 'TOP' },
          { pathname: '/privacy', crumbLabel: 'プライバシーポリシー' },
        ]}
      />
      <PrivacySection companyName={companyName} />
    </Layout>
  );
};

export default PrivacyPage;

export const Head = () => (
  <Seo
    title="プライバシーポリシー"
    description="デジタルクリエイティブ株式会社のプライバシーポリシーはこちら"
  />
);

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        companyName
      }
    }
  }
`;
